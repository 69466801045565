<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Claim via File Feed</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="computedErrors"></ApiError>
      <v-dialog v-model="showDialog" max-width="500" persistent>
        <v-card>
          <v-card-title>Success</v-card-title>
          <v-card-text>
            <p>File processed successfully.</p>

            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th width="40%" class="text-right">Claims Uploaded</th>
                    <td width="60%">
                      {{ claimUploadResponse.claimsUploaded }}
                    </td>
                  </tr>
                  <tr>
                    <th width="40%" class="text-right">Claims Submitted</th>
                    <td width="60%">
                      {{ claimUploadResponse.claimsSubmitted }}
                    </td>
                  </tr>
                  <tr>
                    <th width="40%" class="text-right">Claims Errored</th>
                    <td width="60%">
                      {{ claimUploadResponse.claimsErrored }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p class="mt-5" v-if="claimUploadResponse.claimsErrored > 0">
              <router-link :to="{ name: 'cprMyCompanyClaims' }">Click here to review Claims in Error </router-link>
            </p>
          </v-card-text>
          <v-card-actions
            ><v-spacer></v-spacer
            ><v-btn
              @click="
                showDialog = false;
                loading = false;
              "
              class="primary"
              >OK</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
      <v-container fill-height>
        <v-row dense
          ><v-col cols="4">
            <v-card height="100%">
              <v-card-title primary-title>
                1) Select a Type of Claim
              </v-card-title>
              <v-card-text>
                <v-select
                  v-model="selectedClaimType"
                  :items="claimTypes"
                  label="Select a Type of Claim"
                  :loading="loading"
                  :disabled="loading"
                  item-value="claimTypeId"
                  item-text="claimTypeName"
                  clearable
                  return-object
                ></v-select>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card height="100%">
              <v-card-title primary-title>
                2) Download Claim Template
              </v-card-title>
              <v-card-text>
                <v-btn
                  class="primary"
                  :disabled="!selectedClaimType || loading"
                  :loading="loading"
                  @click="downloadTemplate()"
                  >Download Template</v-btn
                ><br /><br />
                <v-icon color="warning">mdi-alert</v-icon> Don't forget to remove sample data
                <p class="mt-3">Some things to be aware of:</p>
                <ul>
                  <li>A maximum of 250 rows can be uploaded at one time</li>
                  <li>Make sure you've selected the correct type of claim</li>
                  <li>Column headings with an asterisk (*) are required</li>
                  <li>Do not change the order of the columns in template</li>
                  <li>If a claim is invalid after upload, it will will become 'Incomplete'</li>
                  <li>
                    <router-link :to="{ name: 'cprMyClaims', query: { tab: 2 } }">Incomplete Claims</router-link>
                    can be deleted or submitted after upload
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card height="100%">
              <v-card-title primary-title>
                3) Upload Claims File
              </v-card-title>
              <v-card-text>
                <v-file-input v-model="templateToUpload" :disabled="!selectedClaimType" :loading="loading">
                </v-file-input>
                <v-checkbox
                  v-model="sendMails"
                  :disabled="!selectedClaimType || loading"
                  label="Check to send an email for every claim in file?"
                >
                </v-checkbox>
                <v-btn
                  :disabled="!selectedClaimType || !templateToUpload || loading"
                  @click="processTemplate"
                  :loading="loading"
                  class="primary"
                  >Submit</v-btn
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "../../gapp-components/components/display/ApiError.vue";
export default {
  components: { ApiError },
  name: "CprClaimsFileFeed",
  metaInfo: {
    title: "Claims File Feed"
  },
  data() {
    return {
      errors: {},
      claimTypes: [],
      selectedClaimType: null,
      templateToUpload: null,
      loading: false,
      showDialog: false,
      sendMails: false,
      claimUploadResponse: { claimsErrored: 0, claimsSubmitted: 0, claimsUploaded: 0 }
    };
  },
  methods: {
    downloadTemplate() {
      this.$api.get("/api/claimUploads/generateTemplate/" + this.selectedClaimType.claimTypeId).then(({ data }) => {
        window.location.href = data.href;
      });
    },
    processTemplate() {
      this.claimUploadResponse = { claimsErrored: 0, claimsSubmitted: 0, claimsUploaded: 0 };

      this.loading = true;
      this.errors = {};
      let formData = new FormData();
      formData.append("claimTypeId", this.selectedClaimType.claimTypeId);
      formData.append("sendMails", this.sendMails);
      formData.append("file", this.templateToUpload);
      this.$api
        .post("/api/claimUploads/processTemplate", formData)
        .then(({ data }) => {
          this.claimUploadResponse = data;

          // clear form
          this.selectedClaimType = null;
          this.templateToUpload = null;
          this.sendMails = false;

          // show dialog
          this.showDialog = true;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
          this.templateToUpload = null;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchEligibleClaimTypes() {
      this.loading = true;
      this.$api
        .post("/api/promotions/search/eligible/byPromotionCountsByClaimType", {})
        .then(({ data }) => {
          this.claimTypes = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Claims via file feed" }
    ]);
    this.fetchEligibleClaimTypes();
  },
  computed: {
    computedErrors() {
      return this.errors;
    }
  }
};
</script>
